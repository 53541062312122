import {
  THead,
  TR as SdsTr,
  TH as SdsTh,
  TBody,
  TD as SdsTd,
  Accordion,
  Button,
  Icons
} from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';
import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { createMap, viewPdf } from 'utils/helpers';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import { TypeVehicleDocumentEnum } from 'utils/constants/constants';
import {
  DocumentRef,
  DocumentsDto,
  VehicleDetailsDto,
  VehicleDocumentMap
} from './types';
import { FileInput } from '../../../../Telepass/OBU/OBUTollServiceModification/components/styles';
import { constructErrors } from '../../../utils/helper';

const StyledAccordion = styled(Accordion)`
  .shell-accordion-content-box {
    padding: 0;
  }
`;

async function fetchVehicleDocuments(vehicleIds: string[]) {
  return customerInstance.post('document/get-vehicle-documents', {
    vehicleIds
  });
}

const DocumentSelection = forwardRef<DocumentRef>((_props, ref) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [documentMap, setDocumentMap] = useState<VehicleDocumentMap | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { errorDialog, infoDialog } = useConfirmDialogs();

  const { vehicleDetails } = values;
  const vehicleIds = vehicleDetails?.map(
    (vehicle: VehicleDetailsDto) => vehicle.vehicleId
  );

  const {
    isError,
    data: vehicleDocument,
    isLoading
  } = useQuery({
    queryKey: ['obu-vehicle-documents', ...(vehicleIds || [])],
    queryFn: () => fetchVehicleDocuments(vehicleIds).then((res) => res.data)
  });

  const handleViewFile = (document: DocumentsDto) => {
    if (document.storageId) {
      customerInstance
        .post('document/view-file', {
          storageId: document.storageId
        })
        .then((response) => {
          // To be implemented
          const { data: file } = response;
          viewPdf(file.FileContent as string);
        })
        .catch((error) => {
          const message = 'Document can not be opened...Something is wrong';
          errorDialog('Error', errorHelper(error, message));
        });
    } else {
      viewPdf(document.fileContent as string);
    }
  };

  useEffect(() => {
    if (vehicleDocument?.data) {
      setDocumentMap(
        createMap(
          vehicleDocument.data,
          'vehicleId'
        ) as unknown as VehicleDocumentMap
      );
    }
  }, [vehicleDocument]);

  useEffect(() => {
    if (documentMap) {
      (values.vehicleDetails as VehicleDetailsDto[]).forEach((vehicle) => {
        const vehicleDoc = documentMap[vehicle.vehicleId];
        if (vehicleDoc) {
          vehicle.documents = vehicleDoc?.documentDetails?.map((document) => ({
            documentTypeId: document.documentTypeId,
            storageId: document.storageId,
            fileContent: null,
            fileName: document.documentFileName,
            fileExtension: null
          }));
        }
      });
      setFieldValue('vehicleDetails', vehicleDetails);
    }
  }, [documentMap]);

  const readUploadFile = (
    event: ChangeEvent<HTMLInputElement>,
    document: DocumentsDto,
    vehicleId: string
  ) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      processFile(file, document, vehicleId);

      event.target.value = ''; // clear the file input
    }
  };

  const processFile = (
    file: File,
    document: DocumentsDto,
    vehicleId: string
  ) => {
    // support only pdf files
    if (file.type !== 'application/pdf') {
      errorDialog('', 'Only PDF files are allowed.');
      return;
    }

    const MAX_FILE_SIZE = 2097152; // 2 MB in bytes
    if (file?.size > MAX_FILE_SIZE) {
      errorDialog('', 'File size exceeds 2 MB. Please choose a smaller file.');
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        const resultString = reader.result as string;

        const base64Data = resultString.replace(/^data:(.*,)?/, '');
        replaceDocumentWithNew(document, vehicleId, base64Data, file.name);
      };

      reader.readAsDataURL(file);
    }
  };

  const replaceDocumentWithNew = (
    document: DocumentsDto,
    vehicleId: string,
    base64Data: string,
    fileName: string
  ) => {
    (values.vehicleDetails as VehicleDetailsDto[]).forEach((vehicle) => {
      if (vehicle.vehicleId === vehicleId) {
        vehicle.documents = vehicle.documents?.map((doc) => {
          if (doc.documentTypeId === document.documentTypeId) {
            return {
              ...doc,
              storageId: null,
              fileContent: base64Data,
              fileName,
              fileExtension: 'pdf'
            };
          }
          return doc;
        });
      }
    });
    setFieldValue('vehicleDetails', vehicleDetails);
  };

  useImperativeHandle(ref, () => ({
    validateDocument() {
      const VALIDATION_TITLE = 'Validation Error';

      if (isError || isLoading) {
        return false;
      }

      const errors: string[] = [];

      const vehicleList = values?.vehicleDetails as VehicleDetailsDto[];
      if (vehicleList?.length) {
        for (const vehicle of vehicleList) {
          const vehicleRegistrationDocument = vehicle.documents?.find(
            (doc) =>
              doc.documentTypeId ===
              TypeVehicleDocumentEnum.Vehicle_Registration_document.key
          );

          if (!vehicleRegistrationDocument?.fileName) {
            errors.push(
              `Vehicle ${
                documentMap?.[vehicle.vehicleId]?.licensePlate
              } doesn't have registration document uploaded!`
            );
          }
        }

        if (errors.length) {
          infoDialog(VALIDATION_TITLE, constructErrors(errors));
          return false;
        }
      }
      return true;
    }
  }));

  useEffect(() => {
    setFieldValue('documentLoading', isLoading);
  }, [isLoading]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto p-3 bg-shellExtraPaleGrey2"
      data-testid="obu-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <table className="w-full bg-white">
          <THead>
            <SdsTr>
              <SdsTh>Document type</SdsTh>
              <SdsTh>File</SdsTh>
              <SdsTh>Action</SdsTh>
            </SdsTr>
          </THead>
          <TBody>
            <SdsTr>
              <SdsTd colSpan={3} className="!p-0">
                <StyledAccordion
                  fullWidth
                  iconSet="chevron"
                  defaultActiveKey={vehicleIds}
                  items={vehicleDetails?.map((vehicle: VehicleDetailsDto) => ({
                    key: vehicle.vehicleId,
                    label: `Vehicle LPN: ${
                      documentMap?.[vehicle.vehicleId]?.licensePlate
                    }`,
                    children: (
                      <table className="w-full">
                        <TBody>
                          {vehicle.documents?.map((document: DocumentsDto) => (
                            <SdsTr key={document.documentTypeId}>
                              <SdsTd>
                                {document.documentTypeId ===
                                'c3a0fa3b-c4d9-4c59-83a0-44c8c87decd6'
                                  ? 'Vehicle Registration documents'
                                  : 'Vehicle Euro Certificate document'}
                              </SdsTd>
                              <SdsTd>{document.fileName}</SdsTd>
                              <SdsTd>
                                <div className="flex gap-2">
                                  <Button
                                    size="small"
                                    className="cursor-pointer"
                                    data-testid="upload-button"
                                    onKeyDown={(e) => {
                                      if (
                                        (e.key === 'Enter' ||
                                          e.code === 'Space') &&
                                        fileInputRef.current
                                      ) {
                                        fileInputRef.current.click();
                                      }
                                    }}
                                  >
                                    <div className="flex gap-2">
                                      <div className="">
                                        <Icons.UploadCloud
                                          width={15}
                                          height={15}
                                        />
                                      </div>
                                      <div>Select file</div>
                                      <FileInput
                                        type="file"
                                        id="file-input"
                                        data-testid="file-input"
                                        name="file-input"
                                        accept=".pdf"
                                        onChange={(e) =>
                                          readUploadFile(
                                            e,
                                            document,
                                            vehicle.vehicleId
                                          )
                                        }
                                        tabIndex={-1}
                                        ref={fileInputRef}
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </Button>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleViewFile(document)}
                                    data-testid="view-file"
                                  >
                                    View file
                                  </Button>
                                </div>
                              </SdsTd>
                            </SdsTr>
                          ))}
                        </TBody>
                      </table>
                    )
                  }))}
                />
              </SdsTd>
            </SdsTr>
          </TBody>
        </table>
      </QueryError>
    </div>
  );
});

export default DocumentSelection;
