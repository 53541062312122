import {
  Alert,
  Alignments,
  Modal,
  Prominences,
  Sentiments,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import React, { useEffect, useRef, useState } from 'react';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { errorHelper } from 'utils/helpers/errorHelper';
import { queryClient } from 'react-query';
import useValidateT4E from 'hooks/useValidateT4E';
import styled from 'styled-components';
import LoadingModal from 'components/LoadingModal/LoadingModal';
import {
  TypeProductEnum,
  VehcleProductIDsForHideFinish
} from 'utils/constants/constants';
import AddressesSelection from './components/AddressesSelection';
import CardSelection from './components/CardSelection';
import RentalFeeForm from './components/RentalFeeForm';
import {
  DeliveryAddressRef,
  DocumentRef,
  ProductsRef,
  RentalFee,
  VehicleDetailsDto,
  VehicleProductsDto
} from './components/types';
import Summary from './components/Summary';
import VehicleList from './components/VehicleList';
import Products from './components/Products';
import TollDiscounts from './components/TollDiscounts';
import DocumentSelection from './components/DocumentSelection';
import OBUProduct from '../../OBU/T4EObuOrderActions/components/OBUProduct';
import DeliveryAddress from '../../OBU/T4EObuOrderActions/components/DeliveryAddress';
import DeliveryProduct from '../../OBU/T4EObuOrderActions/components/DeliveryProduct';

interface T4ECompleteCustomerRegistrationProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 12;
const STEP_3 = 3;
const STEP_4 = 4;
const STEP_5 = 5;
const STEP_6 = 6;
const STEP_7 = 7;
const STEP_9 = 9;
const STEP_11 = 11;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Business Address';
    case 2:
      return 'Card';
    case STEP_3:
      return 'Rental Fees';
    case STEP_4:
      return 'Rental fees for never personalized OBUs';
    case STEP_5:
      return 'Vehicle';
    case STEP_6:
      return 'Products';
    case STEP_7:
      return 'Toll Discounts';
    case 8:
      return 'Upload Vehicle Documents';
    case STEP_9:
      return 'OBU Products';
    case 10:
      return 'Delivery Address';
    case STEP_11:
      return 'Delivery Product';

    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const ErrorModal = styled(Modal)`
  & .t4e-confirm-modal-content {
    min-height: auto !important;
  }

  & .t4e-confirm-modal-body {
    width: 100%;
  }
`;

const VehicleProductCheck = (values: FormikValues) => {
  if (values.vehecleProductsIds) {
    // if any one of the id present in VehcleProductIDsForHideFinish then return true
    return values.vehecleProductsIds.some((id: string) =>
      Object.values(VehcleProductIDsForHideFinish).includes(
        id.toLocaleLowerCase()
      )
    );
  }
  return false;
};

const T4ECompleteCustomerRegistration: React.FC<
  T4ECompleteCustomerRegistrationProps
> = ({ CustomerId, customerName, handleOnCloseModal }) => {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const { message, error, loading, data } = useValidateT4E(CustomerId);
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const productsRef = useRef<ProductsRef>(null);
  const documentRef = useRef<DocumentRef>(null);
  const deliveryAddressRef = useRef<DeliveryAddressRef>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>T4E Customer Registration</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = (num: number, isBackClick = false) => {
    if (
      step === STEP_6 &&
      !isBackClick &&
      !productsRef.current?.validateVehicle()
    ) {
      return;
    }

    if (
      step === 8 &&
      !isBackClick &&
      !documentRef.current?.validateDocument()
    ) {
      return;
    }

    if (
      step === 10 &&
      !isBackClick &&
      !deliveryAddressRef.current?.validateAddress()
    ) {
      return;
    }

    handleStepNavigation(num, isBackClick);
  };

  const handleStepNavigation = (num: number, isBackClick: boolean) => {
    if (step === STEP_6 && !isBackClick) {
      handleStep6Navigation();
      return;
    }

    if (step === STEP_7 && !isBackClick) {
      handleStep7Navigation();
      return;
    }

    if (step === 8 && isBackClick) {
      handleStep8BackNavigation();
      return;
    }

    if (step === STEP_9 && isBackClick) {
      handleStep9BackNavigation();
      return;
    }

    if (step === TOTAL_STEPS && isBackClick) {
      handleTotalStepsBackNavigation();
      return;
    }

    setStep(num);
  };

  const handleStep6Navigation = () => {
    const { vehecleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehecleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (includesProduct(TypeProductEnum.Toll_France.key as string)) {
      setStep(STEP_7);
    } else if (
      includesProduct(TypeProductEnum.Hungary.key as string) ||
      includesProduct(TypeProductEnum.Bulgaria.key as string)
    ) {
      setStep(8);
    } else {
      setStep(STEP_9);
    }
  };

  const handleStep7Navigation = () => {
    const { vehecleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehecleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (
      includesProduct(TypeProductEnum.Hungary.key as string) ||
      includesProduct(TypeProductEnum.Bulgaria.key as string)
    ) {
      setStep(8);
    } else {
      setStep(STEP_9);
    }
  };

  const handleStep8BackNavigation = () => {
    const { vehecleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehecleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (includesProduct(TypeProductEnum.Toll_France.key as string)) {
      setStep(STEP_7);
    } else {
      setStep(STEP_6);
    }
  };

  const handleStep9BackNavigation = () => {
    const { vehecleProductsIds } = formRef.current?.values || {};
    const lowerCaseIds = vehecleProductsIds?.map((id: string) =>
      id.toLocaleLowerCase()
    );

    const includesProduct = (productKey: string) =>
      lowerCaseIds?.includes(String(productKey).toLocaleLowerCase());

    if (
      includesProduct(TypeProductEnum.Hungary.key as string) ||
      includesProduct(TypeProductEnum.Bulgaria.key as string)
    ) {
      setStep(8);
    } else if (includesProduct(TypeProductEnum.Toll_France.key as string)) {
      setStep(STEP_7);
    } else {
      setStep(STEP_6);
    }
  };

  const handleTotalStepsBackNavigation = () => {
    const { vehicleDetails, discountNames, deliveryProductTypeID } =
      formRef.current?.values || {};

    if (deliveryProductTypeID) {
      setStep(STEP_11);
    } else if (vehicleDetails?.some((v: VehicleDetailsDto) => v.documents)) {
      setStep(8);
    } else if (discountNames?.length) {
      setStep(STEP_7);
    } else {
      setStep(STEP_6);
    }
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('address', undefined);
        formRef.current?.setFieldValue('card', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('card', undefined);
        break;
      case STEP_5:
        formRef.current?.setFieldValue('vehicles', undefined);
        formRef.current?.setFieldValue('vehicleDetails', undefined);
        break;
      case STEP_6:
        formRef.current?.setFieldValue('vehecleProducts', undefined);
        formRef.current?.setFieldValue('vehecleProductsIds', undefined);
        formRef.current?.setFieldValue('vehicles', undefined);
        formRef.current?.setFieldValue('vehicleDetails', undefined);
        break;
      // case STEP_7:
      //   // formRef.current?.setFieldValue('vehecleProducts', undefined);
      //   // formRef.current?.setFieldValue('vehecleProductsIds', undefined);
      //   break;

      case 8: {
        const vehicleDetails = formRef.current?.values.vehicleDetails;
        if (vehicleDetails) {
          const updatedVehicleDetails = vehicleDetails.map(
            (vehicle: VehicleDetailsDto) => {
              const { documents, ...rest } = vehicle;
              return rest;
            }
          );
          formRef.current?.setFieldValue(
            'vehicleDetails',
            updatedVehicleDetails
          );
        }
        break;
      }
      case STEP_9:
        formRef.current?.setFieldValue('obuProduct', undefined);
        formRef.current?.setFieldValue('productTypeID', undefined);
        break;

      case 10:
        formRef.current?.setFieldValue('obuProduct', undefined);
        formRef.current?.setFieldValue('productTypeID', undefined);
        formRef.current?.setFieldValue('contactID', undefined);
        formRef.current?.setFieldValue('countryTypeID', undefined);
        formRef.current?.setFieldValue('contactPersonFullName', undefined);
        formRef.current?.setFieldValue('contactPersonAddress', undefined);
        break;
      case STEP_11:
        formRef.current?.setFieldValue('contactID', undefined);

        formRef.current?.setFieldValue('deliveryProductTypeID', undefined);
        formRef.current?.setFieldValue('deliveryProduct', undefined);
        break;

      default:
        break;
    }
  };

  const isRentalFeeIncomplete = (rentalFee: RentalFee) => {
    const { startDate, endDate, fee } = rentalFee;
    const hasAnyField = startDate ?? endDate ?? fee;
    const hasAllFields = startDate && endDate && fee;
    return hasAnyField && !hasAllFields;
  };

  const nextDisabled = (values: FormikValues) => {
    switch (step) {
      case 1:
        return !values.address;
      case 2:
        return !values.card;
      case STEP_3:
        if (values.rentalFee) {
          // if any one of the fields is there then everything should be filled
          return !!isRentalFeeIncomplete(values.rentalFee);
        }
        return false;

      case STEP_4:
        if (values.rentalFeeNP) {
          // if any one of the fields is there then everything should be filled
          return !!isRentalFeeIncomplete(values.rentalFeeNP);
        }
        return false;

      case STEP_5:
        return !values.vehicles;
      case STEP_6:
        return !values.vehecleProducts && !values.vehecleProductsIds;
      case STEP_7:
        return !values.discountNames;
      case 8:
        return values?.documentLoading;
      case STEP_9:
        return !values.obuProduct && !values.productTypeID;
      case 10:
        return !values.contactID;
      case STEP_11:
        return !values.deliveryProductTypeID;
      default:
        return false;
    }
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;
    if (step === STEP_6 && !productsRef.current?.validateVehicle()) {
      setSubmitting(false);
      return;
    }

    if (step === STEP_6) {
      setSubmitting(false);
      setStep(TOTAL_STEPS);
      return;
    }

    if (step === 8 && !documentRef.current?.validateDocument()) {
      setSubmitting(false);
      return;
    }

    if (step === 8) {
      setSubmitting(false);
      setStep(TOTAL_STEPS);
      return;
    }

    const {
      address: { addressID },
      card: { cardID },
      rentalFee,
      rentalFeeNP,
      notUsedThreePlusMonthsFee,
      vehicleDetails,
      vehecleProducts,
      discountNames,
      productTypeID: obuProductTypeID,
      contactID: customerContactId,
      deliveryProductTypeID: obuDeliveryProductTypeID
    } = values;

    const payload = {
      CustomerId,
      cardId: cardID,
      addressId: addressID,
      rentalFee,
      rentalFeeNP,
      notUsedThreePlusMonthsFee: parseInt(notUsedThreePlusMonthsFee, 10),
      vehicleDetails,
      productValues: vehecleProducts.map(
        (product: VehicleProductsDto) => product.value
      ),
      discountNames,
      obuProductTypeID,
      customerContactId,
      obuDeliveryProductTypeID
    };

    customerInstance
      .post(t4eEndpoints.saveCompleteRegistration, {
        ...payload
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['customercontracts-list', CustomerId]
        });
        queryClient.invalidateQueries({
          queryKey: ['customer-t4e-validation', CustomerId]
        });
        successDialog('', 'Request has been successfully saved');

        handleOnCloseModal();
        setStep(1);
      })
      .catch((err) => {
        const defaultMessage = 'Failed to save the request.';
        errorDialog('Error', errorHelper(err, defaultMessage));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (data && message) {
      setStep(STEP_5);
    }
  }, [data, message]);

  if (loading) {
    return (
      <LoadingModal
        modalProps={{
          mask: false
        }}
      />
    );
  }

  if (error) {
    return (
      <ErrorModal
        title=" "
        onClose={handleClose}
        open
        showHeader={false}
        closable={false}
        maskClosable={false}
        bodyPadding={false}
        centered
        prefixCls="t4e-confirm-modal"
      >
        <Alert
          sentiment={Sentiments.Warning}
          dismissible
          onDismissClick={handleClose}
        >
          <div className="max-h-56 overflow-auto">
            Failed to validate the customer
          </div>
        </Alert>
      </ErrorModal>
    );
  }

  if (!data) {
    return (
      <ErrorModal
        title=" "
        onClose={handleClose}
        open
        showHeader={false}
        closable={false}
        maskClosable={false}
        bodyPadding={false}
        centered
        prefixCls="t4e-confirm-modal"
      >
        <Alert
          sentiment={Sentiments.Warning}
          dismissible
          onDismissClick={handleClose}
        >
          <div className="max-h-56 overflow-auto">{message}</div>
        </Alert>
      </ErrorModal>
    );
  }

  return (
    <Formik
      initialValues={{
        notUsedThreePlusMonthsFee: '10.0000'
      }}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          loading={loading}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden:
                  ![STEP_6, 8, TOTAL_STEPS].includes(step) ||
                  (step === STEP_6 && VehicleProductCheck(values)),
                style: {
                  display:
                    ![STEP_6, 8, TOTAL_STEPS].includes(step) ||
                    (step === STEP_6 && VehicleProductCheck(values))
                      ? 'none'
                      : ''
                },
                'aria-hidden':
                  ![STEP_6, 8, TOTAL_STEPS].includes(step) ||
                  (step === STEP_6 && VehicleProductCheck(values))
              }
            }
          ]}
        >
          {step === 1 && <AddressesSelection customerId={CustomerId} />}
          {step === 2 && <CardSelection customerId={CustomerId} />}
          {step === STEP_3 && <RentalFeeForm type={0} />}
          {step === STEP_4 && <RentalFeeForm type={1} />}
          {step === STEP_5 && <VehicleList customerId={CustomerId} />}
          {step === STEP_6 && <Products ref={productsRef} />}
          {step === STEP_7 && <TollDiscounts />}
          {step === 8 && <DocumentSelection ref={documentRef} />}
          {step === STEP_9 && (
            <OBUProduct customerId={CustomerId} searchType="" />
          )}
          {step === 10 && (
            <DeliveryAddress customerId={CustomerId} ref={deliveryAddressRef} />
          )}
          {step === STEP_11 && (
            <DeliveryProduct countryTypeID={values.countryTypeID} />
          )}

          {step === TOTAL_STEPS && (
            <Summary title="Complete registration selection:" />
          )}
        </StyledModal>
      )}
    </Formik>
  );
};

export default T4ECompleteCustomerRegistration;
