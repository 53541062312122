import { customerInstance } from 'api';

export async function fetchCustomerPendingVehicles(customerId: string) {
  return customerInstance.post('obu/actions/t4e/get-pending-vehicles', {
    customerId
  });
}

export async function fetchCustomerOpenedRequestsVehicle(customerId: string) {
  return customerInstance.post(
    'obu/actions/t4e/get-obu-opened-requests-vehicle-customerId',
    {
      customerId
    }
  );
}

export async function fetchCustomerVehiclesWithObu(customerId: string) {
  return customerInstance.post(
    'obu/actions/t4e/get-vehicle-with-obu-assigned',
    {
      customerId
    }
  );
}

export async function fetchCustomerPendingObus(customerId: string) {
  return customerInstance.post('obu/actions/t4e/get-pending-obus', {
    customerId
  });
}

export async function fetchCustomerOpenedRequestsObu(customerId: string) {
  return customerInstance.post(
    'obu/actions/t4e/get-obu-opened-requests-obu-customerId',
    {
      customerId
    }
  );
}

export async function fetchVehicleSTVService(
  customerId: string,
  vehicleId: string
) {
  return customerInstance.post(
    'obu/actions/t4e/get-obu-vehicle-card-details-vehicleId',
    {
      customerId,
      vehicleId
    }
  );
}

export function constructErrors(errors: string[]) {
  const result = errors.map((msg: string) => `<li>${msg}</li>`);
  return `<ul class="list-disc list-outside p-2 m-2">${result.join('')}</ul>`;
}
