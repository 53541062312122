import { Tooltip } from '@sede-x/shell-ds-react-framework';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface TableTooltipProps {
  message?: string;
  children: React.ReactNode;
}

const Overlay = styled.span`
  display: block;
  padding: 4px 8px;
`;

const TableTooltip: React.FC<TableTooltipProps> = ({ message, children }) => {
  if (message) {
    return (
      <Tooltip
        overlay={<Overlay>{message}</Overlay>}
        trigger={children as ReactElement}
      />
    );
  }
  return <>{children}</>;
};

export default TableTooltip;
