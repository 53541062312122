import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import Languages from 'utils/StaticData/Customer/languages.json';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field, FormikValues, useFormikContext } from 'formik';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import QueryError from 'components/QueryError';
import useValidateT4E from 'hooks/useValidateT4E';
import { TextArea } from '@sede-x/shell-ds-react-framework';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { CustomerRef } from './types';

async function fetchCustomerDetails(customerId: string) {
  return customerInstance.post(t4eEndpoints.customerDetails, {
    customerId
  });
}

interface CustomerDetailsProps {
  customerId: string;
  checkCustomer?: boolean;
}

const CustomerDetails = forwardRef<CustomerRef, CustomerDetailsProps>(
  ({ customerId, checkCustomer = true }, ref) => {
    const { setValues, values } = useFormikContext<FormikValues>();
    const { success, message, error, loading } = useValidateT4E(
      customerId,
      checkCustomer
    );
    const { infoDialog } = useConfirmDialogs();

    useEffect(() => {
      if (checkCustomer) {
        if (!success) {
          setValues({
            ...values,
            note: message,
            error: true
          });
        } else {
          setValues({
            ...values,
            note: '',
            error: false
          });
        }
      }
    }, [success, message, checkCustomer]);

    const { data, isLoading, isError } = useQuery({
      queryKey: ['t4e-customer-details', customerId],
      queryFn: () => fetchCustomerDetails(customerId).then((res) => res.data),
      refetchOnWindowFocus: 'always'
    });

    const {
      name,
      name1,
      name2,
      customerNo,
      gfnid,
      vatID,
      languageTypeID,
      typeName
    } = data || {};

    useImperativeHandle(ref, () => ({
      validateCustomer() {
        const VALIDATION_TITLE = 'Validation Error';
        if (isError) {
          return false;
        }

        if (!name) {
          infoDialog(VALIDATION_TITLE, 'Customer cannot have an empty name.');
          return false;
        }

        if (!typeName) {
          infoDialog(
            VALIDATION_TITLE,
            'Customer cannot have an empty type name.'
          );
          return false;
        }

        if (!vatID) {
          infoDialog(
            VALIDATION_TITLE,
            'Customer cannot have an empty VAT number.'
          );
          return false;
        }

        if (!languageTypeID) {
          infoDialog(
            VALIDATION_TITLE,
            'There is no language specified for the customer.'
          );
          return false;
        }

        return true;
      }
    }));

    return (
      <QueryError
        isLoading={isLoading || (loading && checkCustomer)}
        isError={isError || error}
      >
        <div className="grid grid-cols-1 md:grid-cols-1 gap-3 p-6">
          <div>
            <Field
              id="type"
              name="type"
              label="Type"
              labelPosition="left"
              component={TextField}
              value="Business"
              readOnly
            />
            <Field
              id="customerNo"
              name="customerNo"
              label="Number"
              labelPosition="left"
              maxLength={8}
              component={TextField}
              required
              value={customerNo}
              readOnly
            />
            <Field
              id="gfnid"
              name="gfnid"
              label="GFN Number"
              labelPosition="left"
              component={TextField}
              value={gfnid}
              readOnly
            />
            <Field
              id="name"
              name="name"
              placeholder="Type here"
              label="Name"
              labelPosition="left"
              component={TextField}
              required
              value={`${name}, ${name1}, ${name2}`}
              readOnly
            />
            <Field
              id="vatID"
              name="vatID"
              placeholder="Type here"
              label="VAT"
              labelPosition="left"
              component={TextField}
              value={vatID}
              readOnly
            />
            <Field
              id="languageTypeID"
              name="languageTypeID"
              placeholder="Select"
              label="Language"
              labelPosition="left"
              component={SelectField}
              getPopupContainer={() => document.body}
              value={languageTypeID}
              options={Languages.map((language) => ({
                value: language.LanguageTypeID,
                label: language.Name
              }))}
              disabled
            />
          </div>
          {values.error && message && (
            <Field
              id="note"
              name="note"
              placeholder="Type here"
              rows={5}
              readOnly
              resize="none"
              component={TextArea}
              value={values.note}
              invalid={!!message}
            />
          )}
        </div>
      </QueryError>
    );
  }
);

export default CustomerDetails;
