import {
  Alignments,
  Prominences,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { StyledModal } from 'Pages/CustomerDetails/components/Actions/styles';
import React, { useRef, useState } from 'react';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import { errorHelper } from 'utils/helpers/errorHelper';
import { queryClient } from 'react-query';
import CustomerDetails from './components/CustomerDetails';
import AddressesSelection from './components/AddressesSelection';
import CardSelection from './components/CardSelection';
import RentalFeeForm from './components/RentalFeeForm';
import { CustomerRef, RentalFee } from './components/types';
import Summary from './components/Summary';

interface T4ECustomerRegistrationProps {
  CustomerId: string;
  customerName: string;
  handleOnCloseModal: () => void;
}

const TOTAL_STEPS = 6;
const STEP_3 = 3;
const STEP_4 = 4;
const STEP_5 = 5;

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Customer Details';
    case 2:
      return 'Business Address';
    case STEP_3:
      return 'Card';
    case STEP_4:
      return 'Rental Fees';
    case STEP_5:
      return 'Rental fees for never personalized OBUs';
    case TOTAL_STEPS:
      return 'Summary';
    default:
      return '';
  }
};

const T4ECustomerRegistration: React.FC<T4ECustomerRegistrationProps> = ({
  CustomerId,
  customerName,
  handleOnCloseModal
}) => {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const customerDetailsRef = useRef<CustomerRef>(null);

  const renderTitle = () => (
    <>
      <div className="flex flex-col space-y-4 pl-2">
        <Text size={Sizes.Small}>T4E Customer Registration</Text>
        <Text prominence={Prominences.Strong} bold size={Sizes.Medium}>
          {getTitles(step)}
        </Text>
      </div>

      <Text className="pl-2" size="small">
        {customerName}
      </Text>
    </>
  );

  const handleOnStepClick = (num: number, isBackClick = false) => {
    if (
      step === 1 &&
      !isBackClick &&
      !customerDetailsRef.current?.validateCustomer()
    ) {
      return;
    }
    setStep(num);
  };

  const handleClose = () => {
    handleOnCloseModal();
    setStep(1);
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('error', undefined);

        formRef.current?.setFieldValue('address', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('address', undefined);

        formRef.current?.setFieldValue('card', undefined);
        break;
      case STEP_4:
        formRef.current?.setFieldValue('card', undefined);
        break;
      default:
        break;
    }
  };

  const isRentalFeeIncomplete = (rentalFee: RentalFee) => {
    const { startDate, endDate, fee } = rentalFee;
    const hasAnyField = startDate ?? endDate ?? fee;
    const hasAllFields = startDate && endDate && fee;
    return hasAnyField && !hasAllFields;
  };

  const nextDisabled = (values: FormikValues) => {
    switch (step) {
      case 1:
        return values.error;
      case 2:
        return !values.address;
      case STEP_3:
        return !values.card;

      case STEP_4:
        if (values.rentalFee) {
          // if any one of the fields is there then everything should be filled
          return isRentalFeeIncomplete(values.rentalFee);
        }
        return false;

      case STEP_5:
        if (values.rentalFeeNP) {
          // if any one of the fields is there then everything should be filled
          return isRentalFeeIncomplete(values.rentalFeeNP);
        }
        return false;

      default:
        return false;
    }
  };

  const handleSave = (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;

    const {
      address: { addressID },
      card: { cardID },
      rentalFee,
      rentalFeeNP,
      notUsedThreePlusMonthsFee
    } = values;

    const data = {
      customerId: CustomerId,
      cardId: cardID,
      addressId: addressID,
      rentalFee,
      rentalFeeForNeverPersonalizedOBUs: rentalFeeNP,
      notUsedThreePlusMonthsFee: parseInt(notUsedThreePlusMonthsFee, 10)
    };

    customerInstance
      .post(t4eEndpoints.saveCustomerRegistration, {
        ...data
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['customercontracts-list', CustomerId]
        });
        queryClient.invalidateQueries({
          queryKey: ['customer-t4e-validation', CustomerId]
        });
        successDialog('', 'Request has been successfully saved');

        handleOnCloseModal();
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to save the request.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <Formik
      initialValues={{
        notUsedThreePlusMonthsFee: '10.0000'
      }}
      enableReinitialize
      onSubmit={handleSave}
      innerRef={formRef}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <StyledModal
          title={renderTitle()}
          open
          width="90%"
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          actions={[
            {
              label: 'CANCEL',
              action: () => {
                handleOnCloseModal();
              },
              props: {
                variant: Variants.Outlined
              }
            },
            {
              label: 'BACK',
              action: () => {
                handleOnClickBack(step);
                handleOnStepClick(step - 1, true);
              },
              props: {
                variant: Variants.Outlined,
                hidden: step === 1,
                style: {
                  display: step === 1 ? 'none' : ''
                }
              }
            },
            {
              label: 'NEXT',
              action: () => {
                handleOnStepClick(step + 1);
              },
              props: {
                disabled: nextDisabled(values),
                hidden: step === TOTAL_STEPS,
                style: {
                  display: step === TOTAL_STEPS ? 'none' : ''
                }
              }
            },
            {
              label: 'FINISH',
              action: () => {
                handleSubmit();
              },
              props: {
                disabled: isSubmitting,
                type: 'submit',
                hidden: step !== TOTAL_STEPS,
                style: {
                  display: step !== TOTAL_STEPS ? 'none' : ''
                },
                'aria-hidden': step !== TOTAL_STEPS
              }
            }
          ]}
        >
          {step === 1 && (
            <CustomerDetails customerId={CustomerId} ref={customerDetailsRef} />
          )}
          {step === 2 && <AddressesSelection customerId={CustomerId} />}
          {step === STEP_3 && <CardSelection customerId={CustomerId} />}
          {step === STEP_4 && <RentalFeeForm type={0} />}
          {step === STEP_5 && <RentalFeeForm type={1} />}
          {step === TOTAL_STEPS && <Summary />}
        </StyledModal>
      )}
    </Formik>
  );
};

export default T4ECustomerRegistration;
