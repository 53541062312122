import { Modal } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }
  .shell-modal-container-content {
    height: 80vh;
  }
`;
