import React, { ChangeEvent } from 'react';
import { Card, Sizes } from '@sede-x/shell-ds-react-framework';
import { Field, useFormikContext } from 'formik';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import CheckBox from 'components/FormCmponents/CheckBox';
import Languages from 'utils/StaticData/Customer/languages.json';
import { useAuth } from 'auth/Authorization';
import { Customer } from '../types';
import MainInfo from './MainInfo';
import Details from './Details';
import ContactDetails from './ContactDetails';
import CommercialInfo from './CommercialInfo';
import ShellConsortiumInfo from './ShellConsortiumInfo';

const CustomerForm: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  const { setFieldValue, values } = useFormikContext<Customer>();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pb-6">
      <div className="flex flex-col gap-3">
        <Card header={{ title: 'Main Info', border: true }} size={Sizes.Small}>
          <MainInfo />
        </Card>
        <Card header={{ title: 'Details', border: true }} size={Sizes.Small}>
          <Details />
        </Card>
        <Card header={{ title: 'Contact', border: true }} size={Sizes.Small}>
          <ContactDetails />
        </Card>
        <Card
          header={{ title: 'Commercial Info', border: true }}
          size={Sizes.Small}
        >
          <CommercialInfo />
        </Card>
      </div>
      <div className="flex flex-col gap-3">
        <Card header={{ title: 'Company', border: true }} size={Sizes.Small}>
          <Field
            id="isCompany"
            name="isCompany"
            placeholder="Type here"
            label="Is Company"
            labelPosition="left"
            component={CheckBox}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target;
              setFieldValue('isCompany', checked);
            }}
            checked={values.isCompany}
            size="small"
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="isKeyAccount"
            name="isKeyAccount"
            placeholder="Type here"
            label="Is Key Account"
            labelPosition="left"
            component={CheckBox}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target;
              setFieldValue('isKeyAccount', checked);
            }}
            checked={values.isKeyAccount}
            size="small"
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
        </Card>
        <Card header={{ title: 'Language', border: true }} size={Sizes.Small}>
          <Field
            id="languageTypeID"
            name="languageTypeID"
            placeholder="Select"
            label="Language 1"
            labelPosition="left"
            component={SelectField}
            getPopupContainer={() => document.body}
            options={Languages.map((language) => ({
              value: language.LanguageTypeID,
              label: language.Name
            }))}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="language2TypeID"
            name="language2TypeID"
            placeholder="Select"
            label="Language 2"
            labelPosition="left"
            component={SelectField}
            getPopupContainer={() => document.body}
            options={Languages.map((language) => ({
              value: language.LanguageTypeID,
              label: language.Name
            }))}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
        </Card>
        <Card
          header={{
            title: 'Shell Consortium',
            description:
              'Uploading of EU license is required if the validity is more than 10 years',
            border: true
          }}
          size={Sizes.Small}
        >
          <ShellConsortiumInfo />
        </Card>
        <Card
          header={{ title: 'Market Segmentation', border: true }}
          size={Sizes.Small}
        >
          <Field
            id="marketSegmentationID"
            name="marketSegmentationID"
            placeholder="Type here"
            label="ID"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="marketSegmentationName"
            name="marketSegmentationName"
            placeholder="Type here"
            label="Name"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
        </Card>
      </div>
    </div>
  );
};
export default CustomerForm;
