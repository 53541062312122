import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { FormikValues, useFormikContext } from 'formik';
import { useSdsPagination } from 'hooks/use-pagination';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { TypeProductEnum } from 'utils/constants/constants';
import { ProductsRef, VehicleProductsDto } from './types';
import { VehicleValidator } from '../../../utils/VehicleValidator';
import { constructErrors } from '../../../utils/helper';

async function fetchVehicleProducts(pageNumber: number, pageSize: number) {
  return customerInstance.post(t4eEndpoints.vehicleProducts, {
    pageNumber,
    pageSize
  });
}

const DEFAULT_PAGE_SIZE = 20;

const Products = forwardRef<ProductsRef>((_props, ref) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [selectedRows, setSelectedRows] = useState<unknown[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { infoDialog } = useConfirmDialogs();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['t4e-vehicle-products'],
    queryFn: () =>
      fetchVehicleProducts(pageNumber, pageSize).then((res) => res.data),
    refetchOnWindowFocus: 'always'
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const selectedRowsIds = selectedRows.map(
        (row) => (row as VehicleProductsDto).productTypeID
      );
      setFieldValue('vehecleProducts', selectedRows);
      setFieldValue('vehecleProductsIds', selectedRowsIds);
    } else {
      setFieldValue('vehecleProducts', undefined);
      setFieldValue('vehecleProductsIds', undefined);
    }
  }, [selectedRows]);

  useImperativeHandle(ref, () => ({
    validateVehicle() {
      const VALIDATION_TITLE = 'Validation Error';
      const error: string[] = [];

      const vehicles = values?.vehicles;

      if (isError) {
        return false;
      }
      if (!selectedRows.length) {
        infoDialog(
          VALIDATION_TITLE,
          'Select at least one vehicle first, then continue.'
        );
        return false;
      }
      if (
        values?.vehecleProductsIds?.includes(
          String(TypeProductEnum.Hungary.key).toLocaleLowerCase()
        )
      ) {
        error.push(...VehicleValidator.ValidateForHungary(vehicles));
      }

      // if switzerland is selected, check for switzerland validation
      if (
        values?.vehecleProductsIds?.includes(
          String(TypeProductEnum.Switzerland.key).toLocaleLowerCase()
        )
      ) {
        error.push(...VehicleValidator.ValidateForSwitzerland(vehicles));
      }

      if (
        values?.vehecleProductsIds?.includes(
          String(TypeProductEnum.Austrian_Toll.key).toLocaleLowerCase()
        )
      ) {
        error.push(...VehicleValidator.ValidateForAustria(vehicles));
      }

      if (
        values?.vehecleProductsIds?.includes(
          String(TypeProductEnum.Toll_Germany.key).toLocaleLowerCase()
        )
      ) {
        error.push(...VehicleValidator.ValidateForGermany(vehicles));
      }

      if (
        values?.vehecleProductsIds?.includes(
          String(TypeProductEnum.Belgium.key).toLocaleLowerCase()
        )
      ) {
        error.push(...VehicleValidator.ValidateForBelgium(vehicles));
      }

      if (error.length) {
        infoDialog(VALIDATION_TITLE, constructErrors(error));
        return false;
      }

      return true;
    }
  }));

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="customer-t4e-product-selection-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table
          data={data?.data ?? []}
          columns={getOverviewColumns('T4EVehicleProduct')}
          columnSelection={false}
          exportEnabled={false}
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          stickyColumns={['actions']}
        />
      </QueryError>
    </div>
  );
});

export default Products;
