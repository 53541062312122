import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { useFormikContext } from 'formik';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import {
  DeliveryAddressDto,
  DeliveryAddressRef,
  SaveOBUOrderDto
} from './types';
import { constructErrors } from '../../../utils/helper';

const DEFAULT_PAGE_SIZE = 20;
interface DeliveryAddressProps {
  customerId?: string;
}

async function fetchContactList(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.contactList, {
    customerId,
    pageNumber,
    pageSize
  });
}

const DeliveryAddress = forwardRef<DeliveryAddressRef, DeliveryAddressProps>(
  ({ customerId }, ref) => {
    const { setFieldValue } = useFormikContext<SaveOBUOrderDto>();
    const [selectedRows, setSelectedRows] = useState<DeliveryAddressDto[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const { infoDialog } = useConfirmDialogs();

    const { data, isLoading, isError } = useQuery({
      queryKey: ['search-contact', customerId, pageSize, pageNumber],
      queryFn: () =>
        fetchContactList(customerId as string, pageNumber, pageSize).then(
          (res) => res.data
        ),
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    });

    const handleChangePagination = (
      newPageNumber: number,
      newPageSize: number
    ) => {
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
    };

    const paginationProps = useSdsPagination(handleChangePagination);

    useEffect(() => {
      if (selectedRows.length) {
        const row = selectedRows[0];
        setFieldValue('contactID', row.contactID);
        setFieldValue('countryTypeID', row.countryTypeID);
        setFieldValue('contactPersonFullName', row.fullName);
        setFieldValue('contactPersonAddress', row.city);
        setFieldValue('contactDetails', row);
      } else {
        setFieldValue('contactID', undefined);
        setFieldValue('countryTypeID', undefined);
        setFieldValue('contactPersonFullName', undefined);
        setFieldValue('contactPersonAddress', undefined);
        setFieldValue('contactDetails', undefined);
      }
    }, [selectedRows]);

    useImperativeHandle(ref, () => ({
      validateAddress() {
        const VALIDATION_TITLE = 'Validation Error';

        if (isError || isLoading) {
          return false;
        }

        const errors: string[] = [];
        const selectedContact = selectedRows[0];

        if (!selectedContact?.firstName) {
          errors.push('There is no First name defined for selected contact.');
        }

        if (!selectedContact?.lastName) {
          errors.push('There is no Last name defined for selected contact.');
        }

        if (!selectedContact?.phone) {
          errors.push('There is no phone defined for selected contact.');
        }

        if (!selectedContact?.mail) {
          errors.push('There is no mail defined for selected contact.');
        }

        if (errors.length) {
          infoDialog(VALIDATION_TITLE, constructErrors(errors));
          return false;
        }
        return true;
      }
    }));

    return (
      <div
        className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
        data-testid="obu-list"
      >
        <QueryError isLoading={isLoading} isError={isError}>
          <Table<DeliveryAddressDto>
            data={data?.data ?? []}
            columns={
              getOverviewColumns(
                'DeliveryAddress'
              ) as ColumnDef<DeliveryAddressDto>[]
            }
            columnSelection={false}
            exportEnabled={false}
            enableMultiRowSelection={false}
            onSelectedRowsChange={setSelectedRows}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
          />
        </QueryError>
      </div>
    );
  }
);

export default DeliveryAddress;
