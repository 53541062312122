import { useEffect, useState } from 'react';
import {
  FormField,
  Sizes,
  TextInput,
  Tooltip
} from '@sede-x/shell-ds-react-framework';
import { useAuth } from 'auth/Authorization';
import { ParameterDto, TollDetailsDto, UpdateTollSpecificDto } from './types';
import ParameterDelete from './ParameterDelete';
import { StyledSelect } from './styles';

interface RenderAccordionContentProps {
  type: string;
  id: string;
  updateDataList: UpdateTollSpecificDto[];
  onEdit: (data: UpdateTollSpecificDto[]) => void;
  toll: TollDetailsDto;
}

export const TollIdMap: Record<string, string> = {
  customer: 'customerTollId',
  obu: 'obuTollId',
  contract: 'contractTollId',
  vehicle: 'vehicleTollId',
  contact: 'contactTollId'
};

const AccordionContent: React.FC<RenderAccordionContentProps> = ({
  type,
  id,
  updateDataList,
  onEdit,
  toll
}) => {
  const { verifyRoles, roles } = useAuth();
  const [fields, setFields] = useState<ParameterDto[]>([]);

  const { tollSpecificDetails } = toll;

  useEffect(() => {
    setFields([...tollSpecificDetails]);
  }, [toll, tollSpecificDetails]);

  useEffect(() => {
    // find changed field with respect to initial data
    const changedFields = fields.filter((field) => {
      const initialField = tollSpecificDetails.find(
        (param) => param.tollFieldTypeId === field.tollFieldTypeId
      );

      if (initialField?.value === null && field.value === '') {
        return false;
      }
      return initialField?.value?.toLowerCase() !== field?.value?.toLowerCase();
    });

    // create to update list with changed fields
    const toUpdateList = changedFields.map((field) => ({
      tollId: toll.tollId,
      parameter: field.parameter,
      value: field.value,
      tollName: toll.tollName,
      tableName: field.tableName
    }));

    // remove all fields with same tollName from updateDataList payload
    const updatedList = updateDataList.filter(
      (update) => update.tollName !== toll.tollName
    );

    // update the list with new fields
    onEdit([...updatedList, ...toUpdateList]);
  }, [fields]);

  const handleChange = (value: string, fieldId: string) => {
    const fieldValue = value ?? '';

    const updatedFields = fields?.map((field) => {
      if (field.tollFieldTypeId === fieldId) {
        return {
          ...field,
          value: fieldValue
        };
      }
      return field;
    });

    setFields(updatedFields);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-3">
        {fields?.map((field, i) => {
          const { parameter, value, tollFieldTypeId, parameterValues } = field;

          return (
            <div key={tollFieldTypeId} className="flex flex-col mb-2">
              <FormField size={Sizes.Medium} label={parameter}>
                <div className="items-center flex gap-2">
                  {parameterValues?.length ? (
                    <StyledSelect
                      options={parameterValues.map((item) => ({
                        label: item.dropdownText,
                        value: item.dropdownValue?.toLowerCase()
                      }))}
                      value={value?.toLowerCase()}
                      optionLabelProp="label"
                      optionFilterProp="label"
                      onChange={(v) => handleChange(v, tollFieldTypeId)}
                      disabled={verifyRoles([roles.SOTR_VIEW])}
                      allowClear={value !== '' || value !== null}
                    />
                  ) : (
                    <TextInput
                      value={value ?? ''}
                      onChange={(e) =>
                        handleChange(e.target.value, tollFieldTypeId)
                      }
                      readOnly={verifyRoles([roles.SOTR_VIEW])}
                      data-testid={`${parameter}-input`}
                    />
                  )}
                  <Tooltip
                    overlay="Remove"
                    trigger={
                      <ParameterDelete
                        parameter={tollSpecificDetails[i]}
                        type={type}
                        id={id}
                        disabled={verifyRoles([
                          roles.SOTR_VIEW,
                          roles.SOTR_MANAGE,
                          roles.SOTR_SUPER_USER,
                          roles.SOTR_ADMIN
                        ])}
                      />
                    }
                  />
                </div>
              </FormField>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccordionContent;
