import { Card, Sizes } from '@sede-x/shell-ds-react-framework';
import { useFormikContext } from 'formik';
import { MainInfo } from './MainInfo';
import { Details } from './Details';
import { LicensePlates } from './LicensePlates';
import { Dimensions } from './Dimensions';
import { OtherEquipment } from './OtherEquipment';
import { Co2Tolling } from './Co2Tolling';
import { ShellConsortium } from './ShellConsortium';
import { Vehicle } from '../types';

interface VehicleFormProps {
  isDisabled?: boolean;
}

export const VehicleForm: React.FC<VehicleFormProps> = () => {
  const { values } = useFormikContext<Vehicle>();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
      <div className="flex flex-col gap-3">
        <Card header={{ title: 'Main Info', border: true }} size={Sizes.Small}>
          <MainInfo />
        </Card>
        <Card header={{ title: 'Details', border: true }} size={Sizes.Small}>
          <Details />
        </Card>
      </div>
      <div className="flex flex-col gap-3">
        <Card
          header={{ title: 'License plates', border: true }}
          size={Sizes.Small}
        >
          <LicensePlates />
        </Card>
        <Card header={{ title: 'Dimensions', border: true }}>
          <Dimensions />
        </Card>
        <Card
          header={{ title: 'Other equipment', border: true }}
          size={Sizes.Small}
        >
          <OtherEquipment />
        </Card>
        {values?.isShellConsortiumDisplayed && (
          <Card
            header={{ title: 'Shell Consortium', border: true }}
            size={Sizes.Small}
          >
            <ShellConsortium />
          </Card>
        )}
        <Card
          header={{ title: 'CO2 tolling', border: true }}
          size={Sizes.Small}
        >
          <Co2Tolling />
        </Card>
      </div>
    </div>
  );
};
