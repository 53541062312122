import { FormikErrors } from 'formik';
import { Customer } from '../components/Customer/types';

const MANDATORY_MESSAGE = 'Please fill out this mandatory field';
const ERROR_PHONE_COUNTRY_CODE = 'Phone country code is mandatory';
const ERROR_MOBILE_COUNTRY_CODE = 'Mobile country code is mandatory';

const validateDetails = (values: Customer) => {
  const errors: FormikErrors<Customer> = {};

  if (!values.legalForm) {
    errors.legalForm = MANDATORY_MESSAGE;
  }

  if (!values.name) {
    errors.name = MANDATORY_MESSAGE;
  }

  if (!values.name1) {
    errors.name1 = MANDATORY_MESSAGE;
  }

  if (!values.currencyTypeID) {
    errors.currencyTypeID = 'Please select this mandatory field';
  }

  return errors;
};

const validateCustomerMainInfo = (values: Customer) => {
  const errors: FormikErrors<Customer> = {};

  const MAX_LENGTH = 8;

  if (!values.customerNo) {
    errors.customerNo = MANDATORY_MESSAGE;
  } else if (values.customerNo.length < MAX_LENGTH) {
    errors.customerNo = 'Please enter 8 digits';
  }

  if (!values.colcoCountryTypeID) {
    errors.colcoCountryTypeID = 'Please select Shell colco';
  }

  if (!values.countryTypeID) {
    errors.countryTypeID = 'Please select country of residence';
  }

  if (!values.payer) {
    errors.payer = MANDATORY_MESSAGE;
  } else if (values.payer.length < 10) {
    errors.payer = 'Payer must have length of 10';
  }

  if (!values.issuerTypeID) {
    errors.issuerTypeID = 'Please select issuer';
  }

  if (!values.customerTypeID) {
    errors.customerTypeID = 'Please select customer type';
  }

  return errors;
};

export const customerValidate = (values: Customer) => {
  let errors: FormikErrors<Customer> = {};

  errors = { ...errors, ...validateCustomerMainInfo(values) };

  if (!values.phone) {
    errors.phone = MANDATORY_MESSAGE;
  }

  if (!values.phoneCountryCode && !errors.phone) {
    errors.phone = ERROR_PHONE_COUNTRY_CODE;
  }

  if (!values.mobile) {
    errors.mobile = MANDATORY_MESSAGE;
  }

  if (!values.mobileCountryCode && !errors.mobile) {
    errors.mobile = ERROR_MOBILE_COUNTRY_CODE;
  }

  if (!values.mail) {
    errors.mail = MANDATORY_MESSAGE;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)) {
    errors.mail = 'Invalid email';
  }

  errors = { ...errors, ...validateDetails(values) };

  return errors;
};

export const customerInitialValues: Customer = {
  customerTypeID: 'a0b2e0d4-7c47-4069-8bf6-ec47b12fce87',
  countryTypeID: null,
  currencyTypeID: 'ef85a560-5e9f-4b3b-b2b3-6a23efea6137',
  issuerTypeID: '6E72A0F9-6FBC-4DBC-B0B7-8B60597E897C'.toLocaleLowerCase(),
  colcoCountryTypeID: null,
  customerNo: null,
  gfnid: null,
  legalForm: null,
  name: null,
  name1: null,
  name2: null,
  phoneCountryCode: null,
  phoneAreaCode: '',
  phone: null,
  faxCountryCode: null,
  faxAreaCode: null,
  mobileCountryCode: null,
  mobileAreaCode: '',
  fax: null,
  mobile: null,
  mail: null,
  web: null,
  tradeNumber: null,
  vatNo: null,
  vatID: null,
  isCompany: true,
  isKeyAccount: true,
  status: null,
  createdAt: null,
  createdBy: null,
  lastChangedAt: null,
  lastChangedBy: null,
  payer: null,
  gfnCustomerID: null,
  gfnPayerID: null,
  account: null,
  countryName: null,
  colcoCountryName: null,
  countryCode: null,
  colcoCountryCode: null,
  a2: null,
  countryNo: null,
  colcoCountryNo: null,
  currencyCode: null,
  issuer: null,
  typeName: null,
  euLicense: null,
  euLicenseIssueDate: null,
  euLicenseStartDate: null,
  euLicenseExpiryDate: null,
  initialEuLicense: null,
  initialEuLicenseIssueDate: null,
  initialEuLicenseStartDate: null,
  initialEuLicenseExpiryDate: null,
  currentEuLicense: null,
  currentEuLicenseIssueDate: null,
  currentEuLicenseStartDate: null,
  currentEuLicenseExpiryDate: null,
  france: null,
  belarus: null,
  poland: null,
  ecotax: null,
  slovakia: null,
  czechRepublic: null,
  germany: null,
  fde: null,
  belgium: null,
  eVignette: null,
  eMobility: null,
  t4E: null,
  languageTypeID: null,
  language2TypeID: null,
  marketSegmentationID: null,
  marketSegmentationName: null,
  goodsTransported: null,
  registerCourt: null,
  registerInsertNumber: null,
  registerSection: null,
  isResellerMember: 3,
  memberName: null,
  memberVATID: null,
  memberStreet: null,
  memberStreetNumber: null,
  memberCity: null,
  memberZIP: null,
  memberCountry: null
};
