import React from 'react';
import { Card, Sizes } from '@sede-x/shell-ds-react-framework';
import { Field, useFormikContext } from 'formik';
import TextField from 'components/FormCmponents/TextField';
import Types from 'utils/StaticData/Address/type.json';
import BelgianRegion from 'utils/StaticData/Address/belgianRegion.json';
import ItalianProvince from 'utils/StaticData/Address/italianProvince.json';
import SelectField from 'components/FormCmponents/SelectField';
import CheckBox from 'components/FormCmponents/CheckBox';
import { useAuth } from 'auth/Authorization';
import { Address } from '../types';

const AddressForm: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  const { values } = useFormikContext<Address>();

  return (
    <div className="flex flex-col gap-3">
      <Card header={{ title: 'Address' }} size={Sizes.Small}>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
          <Field
            id="addressTypeID"
            name="addressTypeID"
            placeholder="Select"
            label="Type"
            labelPosition="left"
            component={SelectField}
            options={Types.map((type) => ({
              value: type.AddressTypeID,
              label: type.Name
            }))}
            mandatory
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="street"
            name="street"
            placeholder="Type here"
            label="Street"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="streetNumber"
            name="streetNumber"
            placeholder="Type here"
            label="Street Number"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="pob"
            name="pob"
            placeholder="Type here"
            label="POB"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="addressComplement"
            name="addressComplement"
            placeholder="Type here"
            label="Address"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="zip"
            name="zip"
            placeholder="Type here"
            label="ZIP"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="city"
            name="city"
            placeholder="Type here"
            label="City"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="countryTypeID"
            name="countryTypeID"
            placeholder="Type here"
            label="Country"
            labelPosition="left"
            component={SelectField}
            dataEndPoint="/country"
            dataField="countryID"
            dataLabelField="name"
            onOption
            mandatory
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="isDefault"
            name="isDefault"
            label="Is Default"
            labelPosition="left"
            component={CheckBox}
            checked={values.isDefault}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
        </div>
      </Card>
      <Card header={{ title: 'Telepass' }} size={Sizes.Small}>
        <div className="flex flex-col gap-3">
          <Field
            id="belgianRegionId"
            name="belgianRegionId"
            placeholder="Select"
            label="Belgian Region"
            labelPosition="left"
            component={SelectField}
            getPopupContainer={() => document.body}
            options={BelgianRegion.map((type) => ({
              value: type.EnumID,
              label: type.Region
            }))}
            disabled={
              values.countryTypeID !== '2dfb93b4-027e-4ae4-aa03-3e7138af0223' ||
              verifyRoles([roles.SOTR_VIEW])
            }
            mandatory={
              values.countryTypeID === '2dfb93b4-027e-4ae4-aa03-3e7138af0223'
            }
          />
          <Field
            id="italianProvinceId"
            name="italianProvinceId"
            placeholder="Select"
            label="Italian Province"
            labelPosition="left"
            component={SelectField}
            getPopupContainer={() => document.body}
            options={ItalianProvince.map((type) => ({
              value: type.EnumId,
              label: type.Comune
            }))}
            disabled={
              values.countryTypeID !== '7f50cb6e-aecb-4870-90c4-fcb8799ee539' ||
              verifyRoles([roles.SOTR_VIEW])
            }
            mandatory={
              values.countryTypeID === '7f50cb6e-aecb-4870-90c4-fcb8799ee539'
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default AddressForm;
