import React from 'react';
import { Card, Sizes } from '@sede-x/shell-ds-react-framework';
import { Field, useFormikContext } from 'formik';
import TextField from 'components/FormCmponents/TextField';
import BelgianRegion from 'utils/StaticData/Address/belgianRegion.json';
import ItalianProvince from 'utils/StaticData/Address/italianProvince.json';
import Gender from 'utils/StaticData/Contact/gender.json';
import SelectField from 'components/FormCmponents/SelectField';
import { Label } from 'components/Label/Label';
import ZipCodePicker from 'Pages/CustomerDetails/components/Contact/ContactForm/ZipCodePicker/ZipCodePicker';
import { useAuth } from 'auth/Authorization';
import ContactDetails from './ContactDetails';
import { Contact } from '../types';

const ContactForm: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  const { values } = useFormikContext<Contact>();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
      <div className="flex flex-col gap-3">
        <Card header={{ title: 'Main Info' }} size={Sizes.Small}>
          <div className="flex flex-col gap-3">
            <Field
              id="genderTypeID"
              name="genderTypeID"
              placeholder="Select"
              label="Gender"
              labelPosition="left"
              component={SelectField}
              options={Gender.map((val) => ({
                value: val.GenderTypeID,
                label: val.GenderName
              }))}
              disabled={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="firstName"
              name="firstName"
              placeholder="Type here"
              label="firstName"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="lastName"
              name="lastName"
              placeholder="Type here"
              label="LastName"
              labelPosition="left"
              component={TextField}
              mandatory
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="position"
              name="position"
              placeholder="Type here"
              label="Position"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </Card>
        <Card header={{ title: 'Address' }} size={Sizes.Small}>
          <div className="flex flex-col gap-3">
            <Field
              id="addressComplement"
              name="addressComplement"
              placeholder="Type here"
              label="Address Comp."
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="street"
              name="street"
              placeholder="Type here"
              label="Street"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="streetNumber"
              name="streetNumber"
              placeholder="Type here"
              label="StreetNumber"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="zip"
              name="zip"
              placeholder="Type here"
              label="Zip"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="city"
              name="city"
              placeholder="Type here"
              label="City"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="countryTypeID"
              name="countryTypeID"
              placeholder="Type here"
              label="Country"
              labelPosition="left"
              component={SelectField}
              dataEndPoint="/country"
              dataField="countryID"
              dataLabelField="name"
              onOption
              mandatory
              disabled={verifyRoles([roles.SOTR_VIEW])}
            />
            <Field
              id="addressAddendum"
              name="addressAddendum"
              placeholder="Type here"
              label="Address Addend"
              labelPosition="left"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </Card>
      </div>
      <div className="flex flex-col gap-3">
        <Card header={{ title: 'Telepass' }} size={Sizes.Small}>
          <div className="flex flex-col gap-3">
            <Field
              id="belgianRegionId"
              name="belgianRegionId"
              placeholder="Select"
              label="Belgian Region"
              labelPosition="left"
              component={SelectField}
              options={BelgianRegion.map((type) => ({
                value: type.EnumID,
                label: type.Region
              }))}
              disabled={
                values.countryTypeID !==
                  '2dfb93b4-027e-4ae4-aa03-3e7138af0223' ||
                verifyRoles([roles.SOTR_VIEW])
              }
              mandatory={
                values.countryTypeID === '2dfb93b4-027e-4ae4-aa03-3e7138af0223'
              }
            />
            <Field
              id="italianProvinceId"
              name="italianProvinceId"
              placeholder="Select"
              label="Italian Province"
              labelPosition="left"
              component={SelectField}
              options={ItalianProvince.map((type) => ({
                value: type.EnumId,
                label: type.Comune
              }))}
              disabled={
                values.countryTypeID !==
                  '7f50cb6e-aecb-4870-90c4-fcb8799ee539' ||
                verifyRoles([roles.SOTR_VIEW])
              }
              mandatory={
                values.countryTypeID === '7f50cb6e-aecb-4870-90c4-fcb8799ee539'
              }
            />
            <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2 ">
              <div>
                <Label text="DHL Zip Code" />
              </div>
              <div className="flex w-full col-span-2">
                <div className="flex-grow">
                  <ZipCodePicker />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card header={{ title: 'Media' }} size={Sizes.Small}>
          <ContactDetails />
        </Card>
      </div>
    </div>
  );
};

export default ContactForm;
