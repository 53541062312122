import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { t4eEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { FormikValues, useFormikContext } from 'formik';
import { useSdsPagination } from 'hooks/use-pagination';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { TypeProductEnum } from 'utils/constants/constants';
import {
  ColumnDef,
  RowSelection,
  RowSelectionState
} from '@tanstack/react-table';
import { ProductsRef, TollDiscount, VehicleProductsDto } from './types';
import { VehicleValidator } from '../../../utils/VehicleValidator';
import { constructErrors } from '../../../utils/helper';

async function fetchTollDiscount() {
  return customerInstance.post(t4eEndpoints.TollDiscount);
}

const TollDiscounts = forwardRef<ProductsRef>((props) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const { infoDialog } = useConfirmDialogs();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['t4e-toll-discounts'],
    queryFn: () => fetchTollDiscount().then((res) => res.data),
    refetchOnWindowFocus: 'always'
  });

  // discountNames

  // useEffect(() => {
  //   // if (selectedRows.length) {
  //   //   setFieldValue('discountNames', selectedRows);
  //   // } else {
  //   //   setFieldValue('discountNames', undefined);
  //   // }

  //   // eslint-disable-next-line no-console
  //   console.log(selectedRows);
  // }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="t4e-customer-tollDiscount-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
        <Table<TollDiscount>
          data={data?.data ?? []}
          columns={
            getOverviewColumns('t4eTollDiscount') as ColumnDef<TollDiscount>[]
          }
          columnSelection={false}
          exportEnabled={false}
          rowSelectionState={rowSelection}
          setRowSelectionState={setRowSelection}
          stickyColumns={['actions']}
          getRowId={(row) => row.discountID}
        />
      </QueryError>
    </div>
  );
});

export default TollDiscounts;
